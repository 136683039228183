
.appPhone{
	margin-bottom: 5px;
}

.appPhone .react-tel-input .form-control{
	height: 6.5ch;
	width: 100%;
}

.material-icons {
	cursor: pointer;
	vertical-align: middle !important;
	margin-right: 5px !important;
	color: #727272 !important;
	font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.marker {
	color: red;
}

.markers {
	color: red !important;
}

.markers td {
	color: red !important;
}

.markers td .material-icons{
	color: red !important;
}

.phoneGdpr .react-tel-input .form-control{
	height: 6.5ch;
	width: 50.5ch;
}